%icon_base {
  -webkit-mask-size: contain;
  mask-size: contain;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-position: center;
  text-rendering: auto;
  width: 16px;
  height: 16px;
  min-height: 16px;
  min-width: 16px;
  display: block;
}

.icon-add {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/add.svg');
  mask-image: url('../assets/icon/add.svg');
}

.icon-alert {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/alert.svg');
  mask-image: url('../assets/icon/alert.svg');
}

.icon-arrow-back {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/arrow-back.svg');
  mask-image: url('../assets/icon/arrow-back.svg');
}

.icon-arrows {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/arrows.svg');
  mask-image: url('../assets/icon/arrows.svg');
}

.icon-assistant {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/assistant.svg');
  mask-image: url('../assets/icon/assistant.svg');
}

.icon-block {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/block.svg');
  mask-image: url('../assets/icon/block.svg');
}

.icon-bullet-list {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/bullet-list.svg');
  mask-image: url('../assets/icon/bullet-list.svg');
}

.icon-calendar {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/calendar.svg');
  mask-image: url('../assets/icon/calendar.svg');
}

.icon-calendar-active {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/calendar-active.svg');
  mask-image: url('../assets/icon/calendar-active.svg');
}

.icon-calendar-add {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/calendar-add.svg');
  mask-image: url('../assets/icon/calendar-add.svg');
}

.icon-calendar-disable {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/calendar-disable.svg');
  mask-image: url('../assets/icon/calendar-disable.svg');
}

.icon-calendar-repeat {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/calendar-repeat.svg');
  mask-image: url('../assets/icon/calendar-repeat.svg');
}

.icon-cash {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/cash.svg');
  mask-image: url('../assets/icon/cash.svg');
}

.icon-catalog-categories {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/catalog-categories.svg');
  mask-image: url('../assets/icon/catalog-categories.svg');
}

.icon-catalog-item {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/catalog-item.svg');
  mask-image: url('../assets/icon/catalog-item.svg');
}

.icon-catalog-products {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/catalog-products.svg');
  mask-image: url('../assets/icon/catalog-products.svg');
}

.icon-catalog-treatment {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/catalog-treatment.svg');
  mask-image: url('../assets/icon/catalog-treatment.svg');
}

.icon-center {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/center.svg');
  mask-image: url('../assets/icon/center.svg');
}

.icon-char-arrow-up {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/char-arrow-up.svg');
  mask-image: url('../assets/icon/char-arrow-up.svg');
}

.icon-chart {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/chart.svg');
  mask-image: url('../assets/icon/chart.svg');
}

.icon-chart-arrow-down {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/chart-arrow-down.svg');
  mask-image: url('../assets/icon/chart-arrow-down.svg');
}

.icon-check {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/check.svg');
  mask-image: url('../assets/icon/check.svg');
}

.icon-chevron-down {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/chevron-down.svg');
  mask-image: url('../assets/icon/chevron-down.svg');
}

.icon-chevron-left {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/chevron-left.svg');
  mask-image: url('../assets/icon/chevron-left.svg');
}

.icon-chevron-right {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/chevron-right.svg');
  mask-image: url('../assets/icon/chevron-right.svg');
}

.icon-clock {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/clock.svg');
  mask-image: url('../assets/icon/clock.svg');
}

.icon-close {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/close.svg');
  mask-image: url('../assets/icon/close.svg');
}

.icon-comment {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/comment.svg');
  mask-image: url('../assets/icon/comment.svg');
}

.icon-copy {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/copy.svg');
  mask-image: url('../assets/icon/copy.svg');
}

.icon-credit-card {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/credit-card.svg');
  mask-image: url('../assets/icon/credit-card.svg');
}

.icon-customer {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/customer.svg');
  mask-image: url('../assets/icon/customer.svg');
}

.icon-dashboard {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/dashboard.svg');
  mask-image: url('../assets/icon/dashboard.svg');
}

.icon-economy {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/economy.svg');
  mask-image: url('../assets/icon/economy.svg');
}

.icon-edit {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/edit.svg');
  mask-image: url('../assets/icon/edit.svg');
}

.icon-email {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/email.svg');
  mask-image: url('../assets/icon/email.svg');
}

.icon-export {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/export.svg');
  mask-image: url('../assets/icon/export.svg');
}

.icon-flatpay {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/flatpay.svg');
  mask-image: url('../assets/icon/flatpay.svg');
}

.icon-gift {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/gift.svg');
  mask-image: url('../assets/icon/gift.svg');
}

.icon-graph {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/graph.svg');
  mask-image: url('../assets/icon/graph.svg');
}

.icon-grid {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/grid.svg');
  mask-image: url('../assets/icon/grid.svg');
}

.icon-help {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/help.svg');
  mask-image: url('../assets/icon/help.svg');
}

.icon-image-upload {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/image-upload.svg');
  mask-image: url('../assets/icon/image-upload.svg');
}

.icon-infinite {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/infinite.svg');
  mask-image: url('../assets/icon/infinite.svg');
}

.icon-item {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/item.svg');
  mask-image: url('../assets/icon/item.svg');
}

.icon-journal {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/journal.svg');
  mask-image: url('../assets/icon/journal.svg');
}

.icon-list {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/list.svg');
  mask-image: url('../assets/icon/list.svg');
}

.icon-location {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/location.svg');
  mask-image: url('../assets/icon/location.svg');
}

.icon-lock {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/lock.svg');
  mask-image: url('../assets/icon/lock.svg');
}

.icon-locked {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/locked.svg');
  mask-image: url('../assets/icon/locked.svg');
}

.icon-log-out {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/log-out.svg');
  mask-image: url('../assets/icon/log-out.svg');
}

.icon-minus {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/minus.svg');
  mask-image: url('../assets/icon/minus.svg');
}

.icon-more {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/more.svg');
  mask-image: url('../assets/icon/more.svg');
}

.icon-order {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/order.svg');
  mask-image: url('../assets/icon/order.svg');
}

.icon-phone {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/phone.svg');
  mask-image: url('../assets/icon/phone.svg');
}

.icon-plane {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/plane.svg');
  mask-image: url('../assets/icon/plane.svg');
}

.icon-repeat {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/repeat.svg');
  mask-image: url('../assets/icon/repeat.svg');
}

.icon-search {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/search.svg');
  mask-image: url('../assets/icon/search.svg');
}

.icon-settings {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/settings.svg');
  mask-image: url('../assets/icon/settings.svg');
}

.icon-shield {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/shield.svg');
  mask-image: url('../assets/icon/shield.svg');
}

.icon-sick {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/sick.svg');
  mask-image: url('../assets/icon/sick.svg');
}

.icon-sms {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/sms.svg');
  mask-image: url('../assets/icon/sms.svg');
}

.icon-swap {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/swap.svg');
  mask-image: url('../assets/icon/swap.svg');
}

.icon-three-dots {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/three-dots.svg');
  mask-image: url('../assets/icon/three-dots.svg');
}

.icon-till {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/till.svg');
  mask-image: url('../assets/icon/till.svg');
}

.icon-till-start {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/till-start.svg');
  mask-image: url('../assets/icon/till-start.svg');
}

.icon-till-stop {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/till-stop.svg');
  mask-image: url('../assets/icon/till-stop.svg');
}

.icon-trash {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/trash.svg');
  mask-image: url('../assets/icon/trash.svg');
}

.icon-unlocked {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/unlocked.svg');
  mask-image: url('../assets/icon/unlocked.svg');
}

.icon-user-add {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/user-add.svg');
  mask-image: url('../assets/icon/user-add.svg');
}

.icon-user-single {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/user-single.svg');
  mask-image: url('../assets/icon/user-single.svg');
}

.icon-warranty-badge {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/warranty-badge.svg');
  mask-image: url('../assets/icon/warranty-badge.svg');
}

.icon-x {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/x.svg');
  mask-image: url('../assets/icon/x.svg');
}
