@tailwind base;
@tailwind components;
@tailwind utilities;

@import "styles/font";
@import "styles/text";
@import "styles/components";
@import "styles/button";
@import "styles/img-fill";
@import "styles/icons";

:root {
  @apply w-full h-full;
}

body {
  @apply w-full h-full;
}

* {
	touch-action: manipulation;
	-webkit-tap-highlight-color: transparent;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.router-container {
  @apply w-full h-full max-w-full max-h-full;
}

:root {
  --tui-primary: theme('colors.brand.lilac.400');
  --tui-primary-hover: theme('colors.brand.dark');
}

tui-root {
  height: 100%;
}

[type='text']:not(.shadow-input):focus {
  border-color: transparent;
}

[tuiWrapper] {
  border-width: 1px !important;
  border: none;
  @apply border-brand-gray-200 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none ring-0 outline-none outline-0 #{!important};
}

tui-dialog-host .t-dialog .t-content {
  padding: 0 !important;
  font-family: "DM Sans", serif !important;
}

tui-dialog-host tui-dialog {
  width: auto !important;
  margin: auto !important;
  min-width: auto !important;
  padding: 0 !important;
  border-radius: 1rem !important;
}

tui-dialog-host .t-dialog .t-wrapper .t-close {
  opacity: 0 !important;
}

.variable {
  @apply bg-black/25 px-2 py-0.5 rounded-md;
}

div[contenteditable="true"] .variable {
  @apply bg-[#397EF6] text-white;
}

tui-hint {
  background: rgba(55, 65, 81, 0.80) !important;
  backdrop-filter: blur(4px) !important;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}

.hide-recaptcha .grecaptcha-badge {
  visibility: hidden;
}


